$(function(){

	/**
	 * App configuration
	 */

	var Atma = {
		'deviceType' : ( $( document ).width() < 569 ) ? 'mobile' : 'desktop',
		'hoverDot' : '<div class="hover"><img src="/images/site/bg_nav.png" width="84" height="84" alt=""></div>',
		'pager' : '<dt>images</dt><dd id="pager"></dd>',
		'prevNext' : '<a href="" id="prev" class="prev_next">Prev</a><a href="" id="next" class="prev_next">Next</a>'
	};

	/**
	 * Navbar Toggle
	 * - toggle offscreen navigation.
	 */

	AtmaNavBarToggle = (function() {
		var	$navBarToggle = $( '.navBar-toggle' ),
			$navBarWrapper = $( '.navBar-wrapper' ),
			$html = $( 'html' ),

		toggleNav = function() {
			if ( $html.hasClass( 'navbar--open' ) ) {
			  $navBarToggle.attr( 'aria-expanded', false );
			  $navBarWrapper.attr( 'aria-hidden', true );
			  $html.removeClass( 'navbar--open' );
			} else {
			  $navBarToggle.attr( 'aria-expanded', true );
			  $navBarWrapper.attr( 'aria-hidden', false );
			  $html.addClass( 'navbar--open' );
			}
		},

		initEvents = function() {
			$navBarToggle.on( 'click', function() {
				toggleNav();
			});
		},

		init = function() {
			initEvents();
		};

		return { init: init };
	})();

	AtmaNavBarToggle.init();

	/**
	 * Navbar Items
	 * - animate navbar items at desktop size.
	 */

	AtmaNavBarItems = (function( app ) {
		var $nav_items = $('h1, #nav > ul > li','#header'),
			navHover = false,
			showActiveNav,
			$images = $('#images'),

		fadeAndSetLocation = function( target, link ) {
			target.fadeOut(500, function(){
			  // Unfortunately explorer doesn't set the referrer if you set the window.location
			  if ($.browser.msie) {
				// insert a link and click it!
				var referLink = document.createElement('a');
				referLink.href = link;
				document.body.appendChild(referLink);
				referLink.click();
			  } else {
				window.location = link;
			  }
			});
		},

		navBarActiveClass = function() {
			// Add active class to main nav items... so we can fade out the active item bg in page transitions
			// Remove the default active background set in css for non-js browsers
			if (window.location.pathname == '/') {
			  $('h1').addClass('active').find('a').css({ 'background': 'none' });
			} else {
			  path = window.location.pathname.split('/');
			  $('a[href="/'+ path[1] +'/"]', '#nav').css({ 'background': 'none' }).parent().addClass('active');
			}
		},

		navBarHoverEffects = function() {
			// Main nav hover effects
			$nav_items
			  // not using bg image as we can more easily work around ie png alpha issues with a standard image
			  .append( app.hoverDot )
			  .hover(function() {

				// stops hovers on the active item until another item has been hovered
				// this is needed when moving between pages
				navHover = ( $(this).hasClass('active') ) ? false : true;

				if (navHover) {
				  var $active = $nav_items.filter('.active');
				  $(this).find('.hover').stop(true,true).fadeIn(500);
				  // fade out the active item
				  $active.find('.hover').fadeOut(500, function() {
					$active.removeClass('active').addClass('disabled');
				  });
				  // stop the active item fading back in
				  window.clearTimeout(showActiveNav);
				}
			  }, function() {

				if (navHover) {

				  // mouseout on all items except the active one
				  if ($(this).hasClass('disabled') == false) {
					$(this).find('.hover').stop(true,true).fadeOut(500);
					// fade in the active item if another item isn't hovered within 500 miliseconds
					showActiveNav = window.setTimeout(function() {
					  $nav_items.filter('.disabled').addClass('active').find('.hover').fadeIn(500);
					}, 500);
				  // if we hover off the active item set it back to previous state and don't fade out
				  } else {
					$(this).removeClass('disabled').addClass('active');
				  }
				}

			  }).bind('touchend', function() {
				// stops users needing to touch links twice on iOS devices
				fadeAndSetLocation($images.add('#page').add('.active .hover','#header'), $(this).find('a').attr('href'));
			  });
		},

		navPageTransitions = function() {
			//Navigation page transitions
			$('.subNav a').on( 'click', function(e) {
			 	e.preventDefault();
			 	fadeAndSetLocation( $images, $(this).attr('href') );
			});

			$('#nav a').add('h1 a').on( 'click', function(e) {
				e.preventDefault();
				fadeAndSetLocation($images.add('#page').add('.active .hover','#header'), $(this).attr('href'));
			});
		},

		initEvents = function() {
			$( window ).on( 'debouncedresize', function(){
				if ( $( document ).width() > 569 ) {
					navBarHoverEffects();
					navBarActiveClass();
					navPageTransitions();
				} else {
					// remove items appended to nav that are not necessary at mobile size.
					$nav_items.find( '.hover' ).remove();
				}
			});
		},

		init = function() {
			if ( app.deviceType === 'desktop' ) {
				navBarHoverEffects();
				navBarActiveClass();
				navPageTransitions();
			}

			initEvents();
		};

		return { init: init };
	})( Atma );

	AtmaNavBarItems.init();

	/**
	 * Fade in
	 * - add fade in effects to the nav.
	 */

	AtmaFadeIn = (function() {

		var fadeIn = function() {
			$('html.referrer-pages body.pages #images, html.referrer-pages body.projects #page, html.referrer-pages body.pages #page, html.referrer-projects body.pages #page').fadeIn(500);
			$('.referrer-external h1 a, .referrer-external #nav > ul > li > a, .referrer-external #page, .referrer-external #nav .active .hover, .referrer-external .pages #images')
			  .each(function() {
			    var $el = $(this);
			    $(document).queue(function(n) {
			      $el.css({ opacity: 0, display: 'block' });
			      $el.animate({ opacity: 1 }, {queue: true, duration: 400, complete: n });
			    });
			});
		},

		init = function() {
			fadeIn();
		};

		return { init: init };
	})();

	AtmaFadeIn.init();

	/**
	 * Gallery
	 * - add fullscreen image gallery.
	 */

	AtmaGallery = (function( app ) {
		var $wrapper = $('.projects #wrapper').not('.projects-index #wrapper'),
			$bg = $('<div />'),
			galleryHashChange = true,
			$images = $('#images'),

		// Show the gallery image based on the url hash
		startingImage = function() {
		  var hash = window.location.hash || 0;
		  hash = /\d+/.exec(hash)[0];
		  return (parseInt(hash) || 1) - 1; // images are zero-based
		},

		// Set the URL hash after pager or prev/next events
		setUrlHash = function( index ) {
		  galleryHashChange = true;
		  window.location.hash = index + 1;
		},

		initGallery = function() {

			// Prepend the background div to #page so that we can change the opacity of the background but not the content
			// Setting the opacity here as IE8 doesn't pick up the opacity from the css
			$bg.attr({ 'class': 'background' }).css({ 'opacity': 0.6 });
			$('#page').prepend($bg);

			// Change page opacity on hover
			$('.projects #page').hover(function() {
			    $(this).find('.background').stop(true,true).fadeTo(500, 0.6);
			  }, function() {
			    $(this).find('.background').stop(true,true).fadeTo(500, 0.3);
			  });

			// Append the pager markup for the image gallery
			// $('dl','#page').append( app.pager );

			// Append prev and next navigation for the image gallery
			$( '#images' ).append( app.prevNext );
			//console.log('append prev next');

			$('.prev_next', $wrapper)
			  .hover(function() {
			    $(this).stop().animate({ opacity: 1 }, 500);
			  }, function() {
			    $(this).stop().animate({ opacity: 0.5 }, 500);
			  });

			// Run the image gallery
			$('ul', $images).superGal({
			  loadingTag: '<dd />',
			  loadingContainer: '#page dl',
			  startingImage: startingImage(),
			  speed: 500,
			  pager: '#pager',
			  prev: '#prev',
			  next: '#next',
			  wrap: false,
			  onBeforeImageShow: function( $img, data ) {
			    jQuery.fn.fullscreenr({ width: $img.width(), height: $img.height(), bgID: '#images img:last' });
			  },
			  onImageShow: function( data ) {
			    // show or hide the prev next links as appropriate
			    var index = data.currentImage;
			    $('#prev')[index == 0 ? 'hide' : 'show']();
			    $('#next')[index == data.imageCount - 1 ? 'hide' : 'show']();
			  },
			  onPagerEvent: setUrlHash,
			  onPrevNextEvent: setUrlHash
			});

			// Bind arrow keys to superGal prev and next methods
			$(document).bind('keyup', function(e) {
			  if (e.keyCode == 37) {
			    $('ul', $images).superGal('prev');
			  } else if (e.keyCode == 39) {
			    $('ul', $images).superGal('next');
			  }
			});

			// Load the correct image when using the browsers back/forward buttons
			$(window).hashchange(function(){
			  // don't load image again when the hash has already been set on prev/next or pager links
			  if (galleryHashChange == false) {
			    $('ul', $images).superGal(startingImage());
			  }
			  galleryHashChange = false;
			});

			$(window).hashchange();
		},

		init = function() {
			initGallery();
		};

		return { init: init };
	})( Atma );

	AtmaGallery.init();
});