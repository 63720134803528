/*
 * jQuery SuperGal Plugin
 * http://superbia.com.au
 * Version: 0.1 Pre-release
 * Copyright 2011, Dylan Nichols
 * Inspired by Mike Alsup's cycle plugin and the plugin development posts on learningjQuery.com
 *
 */
;(function($) {
  
  $.fn.superGal = function( options ) {
    return this.each(function(){
      
      var data = {},
          $this = $(this);
      
      // handle arguments/options
      // allows the image index of an existing gallery to be passed in
      data = handleArguments($this, options);

      // Load the image
      if (data) {
        loadImage(data, data.nextImage);
      }
    });
  
  };
    
  function handleArguments( $context, options ) {

    // An image index has been passed so we load existing options and goto the image
    if (options.constructor == String) {
      var direction = options;
      options = $context.parent().data('superGal');
      switch(direction) {
        case 'prev':
          advanceImages( options.context, 0 );
          return false;
        case 'next':
          advanceImages( options.context, 1 );
          return false;
      }
    } else if (options.constructor == Number) {
      var num = options;
      options = $context.parent().data('superGal');
      if (num < 0 || num >= options.imageCount) return false;
      options.nextImage = num;
      return options;
    // Gallery is being run for the first time, setup options
    } else {
      options = $.extend({}, $.fn.superGal.defaults, options);
      options.context = $context.parent();
      options.els = $context.find('a').get();
      options.imageCount = options.els.length;
      options.context.data('superGal', options);
      options.nextImage = options.startingImage;
      
      // Build pager links
      if (options.pager) {
        buildPagerLinks(options);
      }
      
      // Add previous and next links
      if (options.prev && options.next) {
        $(options.prev).bind('click', function(e){ e.preventDefault();advanceImages( options.context, 0 );});
        $(options.next).bind('click', function(e){ e.preventDefault();advanceImages( options.context, 1 );});
      }
      return options;
    }
    
  };
  function loadImage( data, index ) {

    var $loading = $(data.loadingTag).attr(data.loadingAttrs),
        galImage = document.createElement('img');
        
    // Show loading animation
    $(data.loadingContainer).append($loading);
    
    // Image loading
    $(galImage).load(function() {
      var $img = $(this);
      
      // Hide, append
      $img.hide();
      data.context.append($img);
      
      // Run the onBeforeImageShow callback
      data.onBeforeImageShow.call(this, $img, data);
      
      // Fade out and remove existing image
      if ( data.context.find('img').size() > 1) {
        $img.fadeIn(data.speed, function(){
          data.context.find('img:first').remove();
        });
      } else {
        //$img.fadeIn(data.speed);
        $img.show();
      }
      
      // Updated the active pager link
      updateActivePagerLink(data.pager, index);
      
      // Update data
      data.currentImage = index;
      data.context.data('superGal', data);
      
      // Run the onImageShow callback
      data.onImageShow.call(this, data);
      
      // Remove loading spinner
      $loading.remove();
      // implement code so load event fires on cached images
      // https://github.com/desandro/imagesloaded
    }).attr('src', data.els[index]);
    
  };
  function buildPagerLinks( data ) {
    
    var a = [], 
        pos = 0,
        $pager = $(data.pager);
    
    // Create the page links
    $.each(data.els, function( i, v ) {
      a.push('<a href="">'+ (i + 1) +'</a>');
    });
    
    // Append the pager
    $(data.pager).append(a.join(' '));
    
    // Bind click event to the pager
    $('a', $pager).bind('click', function(e){
      e.preventDefault();
      var nextIndex = $(this).index();
      // onPagerEvent Callback
      data.onPagerEvent.call(this, nextIndex);
      // Load the image
      loadImage(data, nextIndex);
    });
    
  };
  function updateActivePagerLink( pager, currentImage ) {
    
    $(pager).find('a').removeClass('active').eq(currentImage).addClass('active');
    
  };
  function advanceImages( context, direction ) {
    
    var data = $(context).data('superGal'),
        increment = (direction) ? 1 : -1;

    data.nextImage = data.currentImage + increment;
    
    // Moved past the start, return the last image unless wrapping set to false
    if (data.nextImage < 0) {
      if (!data.wrap) return false;
      data.nextImage = data.imageCount - 1;
    // Moved past the end goto the first image
    } else if ( data.nextImage >= data.imageCount) {
      if (!data.wrap) return false;
      data.nextImage = 0;
    }
    
    // onPrevNextEvent Callback
    data.onPrevNextEvent.call(this, data.nextImage);
    
    // Load the image
    loadImage(data, data.nextImage);
  };

  //
  // Plugin defaults
  //
  $.fn.superGal.defaults = {
    loadingAttrs: {
      id: 'loading'
    },
    loadingContainer: '#wrapper',
    loadingTag: '<div />',
    onBeforeImageShow: function(){},
    onImageShow: function(){},
    onPagerEvent: function(){},
    onPrevNextEvent: function(){},
    pager: null,
    prev: null,
    next: null,
    speed: 500,
    startingImage: 0,
    wrap: true
  };
  
})(jQuery);